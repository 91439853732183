.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 200px;

  background: #ffffff;
  border-radius: 10px;
  border: 5px solid black;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: scaleUp 0.3s ease-in-out;
}

.modal-btn-container button {
  background: #000;
  color: white;
  font-size: 20px;
  border-radius: 10px;
  padding: 10px 30px;
  border: none;

  margin-top: 15px;
  cursor: pointer;
}

@keyframes scaleUp {
  from {
    transform: translate(-50%, -50%) scale(0);
  }

  to {
    transform: translate(-50%, -50%) scale(1);
  }
}
