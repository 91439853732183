.stat-box {
  width: var(--gridBoxWidth);
  height: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  padding: 5px 0px;

  border-radius: 10px;
  background: transparent;
}

.title {
  font-size: 12px;
  font-weight: 700;
}

.value {
  font-size: 16px;
  font-weight: 700;
}
