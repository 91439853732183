* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html,
#root {
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  background: #605e5e;
}

:root {
  --box: 80px;

  --crossWidth: 15px;
  --crossColor: #81f4e1;

  --circleWidth: 15px;
  --circleColor: #ff729f;

  --gridBoxWidth: 95px;
  --gridBoxColor: rgba(89, 85, 85, 0.5);

  --background: #36eee0;
}
