.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-size {
  display: flex;
  margin-bottom: 20px;
}

.scores {
  margin-top: 15px;
  display: flex;
  gap: 10px;
}

.reset-btn {
  margin-top: 10px;
  border-radius: 8px;
  background: red;
  padding: 8px 12px;

  cursor: pointer;
  color: white;
  font-weight: 700;

  box-shadow: 0 0 2.8rem red;
}
